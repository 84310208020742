
import { computed, defineComponent, ref } from 'vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { CountDown, Skeleton, Toast } from 'vant';
import dayjs from 'dayjs';

import { promoteQuery } from '@/apollo/queries';
import { CheckInRecordsStatusVM } from '@/view-models/promote.vm';
import { commafy, formatDate } from '@/utils/formatter';
import { promoteMutation } from '@/apollo/mutations';
import { RouteRecord } from '@/router';
import { useActivitySetting } from '@/composable/setting';

export default defineComponent({
  components: {
    VanCountDown: CountDown,
    VanSkeleton: Skeleton,
  },
  setup() {
    const countDown = ref<typeof CountDown>();
    const time = ref(0);

    const { dailyCheckInActivitySetting } = useActivitySetting();

    const { result, loading: recordLoading, refetch } = useQuery<{ checkInRecordStatus: CheckInRecordsStatusVM }>(
      promoteQuery.getCheckInRecordStatus,
    );
    const checkInRecordStatus = computed(() => result.value?.checkInRecordStatus ?? null);
    const dailyItems = computed(() => {
      return Array(5)
        .fill('')
        .map((_, i) => {
          const dailyItems = checkInRecordStatus.value?.items ?? [];
          const firstDailyItem = dailyItems[0];

          return dailyItems[i]
            ? dailyItems[i]
            : {
                id: '',
                userId: '',
                dateRecord: (firstDailyItem ? dayjs(firstDailyItem.dateRecord) : dayjs()).add(i, 'day').toISOString(),
                continuousDailyCount: '',
                isAbleToReceiveBonus: false,
                walletHistoryId: '',
                dateCreated: '',
                dateUpdated: '',
              };
        });
    });
    const accDay = computed(() => dailyItems.value.filter((item) => !!item.id).length);

    const { mutate: dailyCheckIn, loading: isLoading, onDone } = useMutation<{
      dailyCheckIn: { financeRcoinAccountHistoryId: string; walletHistoryId: string };
    }>(promoteMutation.dailyCheckIn, {
      refetchQueries: ['checkInRecordStatus'],
    });

    onDone(() => {
      Toast({
        type: 'success',
        message: '完成簽到。',
      });
    });

    function formatOnlyMonthAndDate(date: string | number) {
      return dayjs(date).format('MM/DD');
    }

    function remainingTime({ hours, minutes, seconds }: { hours: number; minutes: number; seconds: number }) {
      switch (true) {
        case hours > 0:
          return `剩餘 ${hours} 小時`;
        case minutes > 0:
          return `剩餘 ${minutes} 分鐘`;
        case seconds > 0:
          return `剩餘 ${seconds} 秒`;
        default:
          return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      }
    }

    function initTime() {
      time.value = dayjs()
        .endOf('day')
        .diff(dayjs(), 'milliseconds');
      if (countDown.value) {
        countDown.value.reset();
      }
    }

    function onFinish() {
      initTime();
      refetch();
    }

    initTime();

    return {
      RouteRecord,
      countDown,
      time,
      checkInRecordStatus,
      dailyItems,
      accDay,
      recordLoading,
      isLoading,
      dailyCheckInActivitySetting,

      commafy,
      formatDate,
      formatOnlyMonthAndDate,
      dailyCheckIn,
      remainingTime,
      onFinish,
    };
  },
});
